import React from 'react';
import CoolPersonalHomepage from './components/CoolPersonalHomepage';

function App() {
  return (
    <div className="App">
      <CoolPersonalHomepage />
    </div>
  );
}

export default App;